@font-face {
    font-family: Reef;
    src: url('./resources/fonts/brandon-grotesque/Brandon_reg.otf') format('truetype'),
    url('./resources/fonts/brandon-grotesque/Brandon_bld.otf') format('truetype'),
    url('./resources/fonts/brandon-grotesque/Brandon_blk.otf') format('truetype'),
    url('./resources/fonts/brandon-grotesque/Brandon_med.otf') format('truetype'),
    url('./resources/fonts/brandon-grotesque/Brandon_light.otf') format('truetype') {} }

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box; }

html {
    overflow-x: hidden; }

html, body {
    height: 100%;
    width: 100%; }

#root {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column; }

#components {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column; }
